
.ql-toolbar {
    border: none !important; /* Remove border from the toolbar */
    box-shadow: none !important; /* Remove shadow if present */
  }
  
  .ql-container {
    border: none !important; /* Remove border from the editor area */
    box-shadow: none !important; /* Remove shadow if present */
  }
  
  .ql-editor {
    /* min-height: 200px; */
  }
    