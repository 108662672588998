.common-input-box{
  display: flex;
  flex-direction: column;
  width: '100%';
}

.common-input{  
  font-size: 14px !important;
}

.common-input-floated-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  color: #000000;
}