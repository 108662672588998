.create-password-container {
  padding: 50px;
  border: 1px solid #DEDEDE;
  margin: auto;
  min-width: 500px;
  max-width: 500px;
}

.page-containers {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 74vh;
  max-height: 100vh;

}