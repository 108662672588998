.content-styled-span {
    font-size: 12.0pt !important;
    line-height: 107% !important;
    color: #222222 !important;
    background: white !important;
  }
  .content-Link-span {
    font-size: 12.0pt !important;
    line-height: 107% !important;
    color: #1155CC !important;
    background: white !important;
  }
  .border-style {
    border-top: solid black 1.0pt !important;
    border-left: none !important;
    border-bottom: solid black 1.5pt !important;
    border-right: solid black 1.0pt !important;
    padding: 0in 5.4pt 0in 5.4pt !important;
  }
  .border-style-1 {
    border-top: none !important;
    border-left: none !important;
    border-bottom: solid black 1.0pt !important;
    border-right: solid black 1.0pt !important;
    padding: 0in 5.4pt 0in 5.4pt !important;
  }
