.platform-details-container{
  padding: 0px 30px 30px 30px;
  border: 1px solid #DEDEDE;
  margin: auto;
  /* min-width: 600px; */
  /* max-width: 800px; */
  border-radius: 20px;
  /* min-height: 630px; */
}

.platform-user-details-container{
  /* padding: 20px 50px; */
  /* border: 1px solid #DEDEDE; */
  margin: auto;
  border-radius: 20px;
  min-height: 630px;
}

.platform-details-container .platform-user-details-container {
  font-family: "Helvetica Neue" !important;
  font-weight: 400;
}

.platform-label{
  font-weight: 600;
  font-size: 14px;
  line-height: 17.09px;
}

.add-button{
  padding: 6px 12px 4px 12px;
  background: rgba(18, 18, 18, 0.12);
  border-radius: 20px;
  border: none;
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #666666;
}

.add-button-active{
  padding: 6px 12px 4px 12px;
  background: rgba(56, 67, 172, 0.12);
  border-radius: 20px;
  border: none;
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #3843AC;
}