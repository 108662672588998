/* Will edit everything selected including everything between a range of dates */
.CalendarDay__selected_span {
  background: rgb(54, 70, 172);
  color: #fff;
  border: 1px solid rgb(54, 70, 172);
}

/* Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
  background: rgb(25, 50, 65);
  color: #fff;
  border: 1px solid rgb(25, 50, 65);
}

/* Will edit when hovered over. _span style also has this property */
.CalendarDay__selected:hover {
  background: rgb(25, 50, 65);
  color: #fff;
  border: 1px solid rgb(25, 50, 65);
}

/* Will edit when the second date (end date) in a range of dates
is not yet selected. Edits the dates between your mouse and said date */
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: rgb(54, 70, 172);
  color: #fff;
  border: 1px solid rgb(54, 70, 172);
}

.CalendarDay__selected_span:active, 
.CalendarDay__selected_span:hover {
  background: rgb(54, 70, 172);
  color: #fff;
  border: 1px solid rgb(54, 70, 172);
}

.DayPickerKeyboardShortcuts_show__bottomRight:before {
  border-right: 33px solid rgb(54, 70, 172);
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover:before {
  border-right: 33px solid rgb(54, 70, 172);
}

.DateInput_input__focused {
  border-bottom: 2px solid rgb(54, 70, 172);;
}