.request-platform-container {
  padding: 50px;
  border: 1px solid #DEDEDE;
  /* margin: auto; */
  min-width: 400px;
  max-width: 500px;
  margin-top: 32px;
}

.cls-for-scroll::-webkit-scrollbar {
  width: 0px;
}

.css-zow5z4-MuiGrid-root {
  box-sizing: border-box;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100% !important;
  flex-direction: row;
  margin-top: 0 !important;
  margin-left: 0 !important;
}