/* .recharts-surface {
    width: 100%;
}
.recharts-default-legend {
    width: 100%;
} */

.recharts-legend-item-text{
    font-size: 14px !important;
}

.DateInput_input {
    font-size: 14px !important;
    line-height: 15px !important;
    padding: 12px 16px 6px !important;
    font-family: 'Helvetica Neue' !important;
}

.DateRangePickerInput_arrow_svg{
    height: 15px !important;
}

.DateInput_input__focused {
    border-bottom: 2px solid rgb(54, 70, 172);;
}