.custom-background {
  background-color: #d9d9d9;
}

.custom-page-margin {
  margin: 0px 50px !important;
}

a,
p,
span,
label {
  font-size: 15px;
}

.radial-img {
  background-image: url("../src/assets/background.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.form-error {
  font-size: 12px;
  color: #ff0000;
  margin-left: 2px;
}

.custom-container {
  position: relative;
  opacity: 0.8;
}

.custom-container input {
  font-size: 14px;
  width: 100%;
  height: 44px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding-left: 13px;
  padding-right: 35px;
}

.custom-container input:hover {
  border: 1px solid #000000;
}

.custom-container input:focus {
  outline: none;
  border: 2px solid #0d6efd;
}

.custom-container img {
  cursor: pointer;
  position: absolute;
  width: 20px;
  right: 8px;
  top: 12px;
  opacity: 0.5;
}

.MuiNativeSelect-select {
  font-size: 14px !important;
  font-family: "Helvetica Neue" !important;
}

.MuiTablePagination-selectLabel {
  margin-top: auto !important;
  font-size: 14px !important;
  font-family: "Helvetica Neue" !important;
}

.MuiTablePagination-displayedRows {
  margin-top: auto !important;
  font-size: 14px !important;
  font-family: "Helvetica Neue" !important;
}

.MuiTablePagination-actions {
  margin-bottom: 5px !important;
  font-size: 14px !important;
  font-family: "Helvetica Neue" !important;
}

.MuiTablePagination-menuItem {
  text-align: center;
}

.MuiNativeSelect-select {
  margin-top: 2px !important;
}

.MuiInputBase-input {
  font-size: 14px !important;
  /* font-family: "Helvetica" !important; */
}

.MuiNativeSelect-icon {
  padding-bottom: 3px !important;
}

.colors {
  color: #666666 !important;
}

@media only screen and (min-width: 1400px) and (max-width: 1535px) {
  html {
    font-size: 14px;
    /* 1rem = 20px */
  }
}

@media only screen and (min-width: 1536) {
  html {
    font-size: 16px;
    /* 1rem = 20px */
  }
}

.first-card-content {
  background-color: #fff !important;
  margin-bottom: 1.5rem !important;
  padding: 1rem !important;
  box-shadow: 0px 0px 1px rgba(37, 38, 45, 0.25), 0px 2px 4px rgba(37, 38, 45, 0.15) !important;
  border-radius: 8px !important;
  padding-bottom: 1.562rem !important;
}

.MuiNativeSelect-icon {
  padding-bottom: 3px !important;
}

.mt-6 {
  margin-top: 6rem;
}

.colors {
  color: #666666 !important;
}

.cancel_style {
  cursor: pointer !important;
  padding: 0px !important;
  background-color: #fff !important;
  color: #000 !important;
  text-transform: none !important;
  width: 106px !important;
  height: 40px !important;
  margin-top: 18px !important;
  display: flex !important;
  align-items: center !important;
  gap: 5px
}

.cancel_style1 {
  cursor: pointer !important;
  padding: 0px !important;
  background-color: #fff !important;
  color: #000 !important;
  text-transform: none !important;
  width: 106px !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  gap: 5px
}

.cardText {
  font-size: 1rem !important;
  color: #66696A !important;
  font-family: "Helvetica" !important;
}

.paddingNone {
  padding: 16px !important;
}

.TableLables {
  text-align: center !important;
  padding: 12px !important;
  color: #1C2B47 !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
}

.TableHeads {
  background: #FAFCFF !important;
  width: 100% !important;
  position: relative !important;
}

.GraphHeading {
  display: flex !important;
  justify-content: start !important;
  align-items: center !important;
  color: #3A3A3A !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  margin: 10px 0 10px 20px !important;
}

.GraphMainHeading {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: #3A3A3A !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.TypegrapycardNumber {
  color: #3A3A3A !important;
  font-size: 1.562rem !important;
}

.Table-contain {
  background-color: #fff !important;
  margin-top: 1rem !important;
  padding: 1rem !important;
  border-radius: 8px !important;
  box-shadow: 0px 0px 1px rgba(37, 38, 45, 0.25), 0px 2px 4px rgba(37, 38, 45, 0.15) !important;

}

.Table-inner-contain1 {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  height: 40px !important;
  margin-bottom: 10px !important;
}

.CommonTag {
  width: 8.75rem !important;
  height: 2.5rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer !important;
}

.TagTypograpy {
  font-family: 'Helvetica Neue' !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
}

.Icon-main-contents {
  display: flex !important;
  align-items: center !important;
  gap: 15px !important;
}

.Cancel-btn {
  font-size: 1rem !important;
  font-weight: 500 !important;
  font-family: Helvetica !important;
  letter-spacing: normal !important;
  color: #3A3A3A !important;
  text-transform: none !important;
}

.date-pickers {
  width: 200px !important;
}

.date-pickers .MuiInputLabel-root {
  transform: translate(5px, -8px) scale(1) !important;
  margin-left: 14px !important;
  font-size: 13px !important;
  color: #66696A !important;
}

.date-pickers .MuiOutlinedInput-root {
  padding-right: 0 !important;
  border-radius: 10px !important;
}

.date-pickers .MuiSelect-iconOutlined {
  right: 12px !important;
}

.date-pickers .MuiSelect-root {
  border-radius: 10px !important;
  font-size: 1rem !important;
}

.date-pickers .MuiOutlinedInput-notchedOutline {
  border-radius: 10px !important;
}

.date-pickers .MuiSelect-iconOutlined {
  right: 12px !important;
}

.date-pickers .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #25262D !important;
}

.rate-selects .MuiSelect-root {
  color: #25262D !important;
  font-size: 1rem !important;
  font-family: Helvetica !important;
  font-weight: 500 !important;
  border-radius: 10px !important;


}

#cpcRate {
  display: flex !important;
  justify-content: space-between !important;
}

.rate-selects .MuiOutlinedInput-notchedOutline {
  border-radius: 10px !important;
}

.rate-selects .MuiSelect-iconOutlined {
  right: 12px !important;
}

.rate-selects.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #25262D !important;
}

.select-options {
  color: #25262D;
  font-size: 1rem !important;
  font-weight: 500 !important;
  background-color: #FFFFFF !important;
  font-family: "Helvetica Neue" !important;

}

.comman-Prefix {
  font-size: 1rem !important;
  font-family: "Helvetica" !important;
  font-weight: 500 !important;
}

.select-label {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: #3A3A3A !important;
  font-size: 1.25rem !important;
}

.select-value {
  display: flex !important;
  margin-left: 0.625rem !important;
  align-items: center !important;
  color: #25262D !important;
  font-size: 1.375rem !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.img-style {
  margin-right: 5px !important;
  margin-bottom: 2px !important;
  cursor: pointer !important;
  z-index: 99;
}

.pricing-form-select {
  display: flex !important;
  justify-content: space-between !important;
  height: 71px !important;
  align-items: center !important;
}

.graph-main-contain {
  background-color: #fff !important;
  padding: 25px !important;
  box-shadow: 0px 0px 1px rgba(37, 38, 45, 0.25), 0px 2px 4px rgba(37, 38, 45, 0.15) !important;
  border-radius: 8px !important;
}

.modal-typography {
  font-weight: 400 !important;
  font-family: "Helvetica Neue" !important;
  font-size: 1rem !important;
  text-align: center !important;
  color: #656C7B !important;
  width: 70% !important;
  margin-bottom: 16px !important;
}

.modal-btn-div {
  width: 100% !important;
  margin-top: 3px !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 1rem !important;
}

.modal-btn {
  cursor: pointer !important;
  width: 106px !important;
  height: 40px !important;
  margin-right: 0.625rem !important;
  text-transform: none !important;
  background-color: #000 !important;
}

.modal-btn:hover {
  background-color: #000 !important;
}

.modal-btn-no {
  cursor: pointer !important;
  background-color: white !important;
  width: 106px !important;
  height: 40px !important;
  color: black !important;
  text-transform: none !important;
}

.custom-currentPlan {
  color: #25262D !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  border-radius: 10px !important;
}

.recharts-tooltip-wrapper:hover {
  outline: transparent !important;
}

.recharts-tooltip-wrapper {
  outline: white !important;
  width: 200px !important;
}

.desc {
  color: #25262D !important;
  font-size: 12px !important;

}

.desc-value {
  font-weight: 600 !important;
  font-size: 12px !important;
  padding: 5px !important;

}

.custom-tooltip {
  border-radius: 10px !important;
  border: none !important;
  outline: none !important;
  box-shadow: 0px 0px 1px rgba(37, 38, 45, 0.25), 0px 2px 4px rgba(37, 38, 45, 0.15) !important;
  background-color: white !important;
  padding-left: 10px !important;

}

.ptop {
  padding-top: 10px !important;
}

.pbottom {
  padding-bottom: 10px !important;
}

.box {
  transition: transform 2s ease;
  /* Define transition property */
}


.first {
  height: 0;
  overflow: hidden;
  transition: height 1s ease-in-out;
}

.second {
  height: 0;
  overflow: hidden;
  transition: height 1s ease-in-out;
}

.expand {
  height: 500px;
}

.CheckboxStype .MuiTypography-root {
  color: rgba(97, 97, 97, 1);
  font-family: "Helvetica" !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.TestText {
  margin-left: 1rem;
  color: rgba(97, 97, 97, 1) !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  margin-top: 8px !important;
}

#headerHeght {
  height: 0 !important;
}

.margin_style {
  margin-left: 10px !important;
}

.signin-container-2 {
  background-color: #fff !important;
  border-radius: 8px !important;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.06) !important;
  padding: 30px !important;
  text-align: center !important;
  max-width: 555px !important;
}

.special-label {
  color: #0000004f !important;
  font-weight: 400 !important;
  line-height: 1.4375em !important;
  letter-spacing: 0.00938em !important;
  padding: 0 !important;
  display: block !important;
  transform-origin: top left !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: calc(133% - 24px) !important;
  position: absolute !important;
  transform: translate(14px, -9px) scale(0.75) !important;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms !important;
  transform: 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms !important;
  max-width: 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms !important;
  z-index: 1 !important;
  pointer-events: auto !important;
  user-select: none !important;
  font-size: 14px !important;
  margin-top: 11px !important;
  opacity: 1 !important;
  background-color: #FFFFFF !important;
  font-family: 'Helvetica Neue' !important;
  padding-right: 25px !important;
  padding-left: 7px !important;
  left: 35px !important
}

#rfs-btn {
  color: #0000004f;
  font-family: Helvetica;
}

/* .countrySelect:hover {
  width: '100%';
  border: '1px solid #ccc';
  borderRadius: '4px';
  padding: '8px';
  transition: 'border-color 0.2s ease, box-shadow 0.2s ease';
} */

#rfs-btn:hover,
#rfs-btn:focus {
  border: 1px solid black !important
}

.react-tel-input .country-list .country {
  padding: 6px 33px !important;
}

.MuiInputBase-root {
  border-radius: 5px !important;
}

.MuiOutlinedInput-root {
  padding: 3px !important
}

.MuiInputLabel-sizeMedium {
  top: -6px !important;
  color: #0000004f !important;
  font-family: helvetica !important;
}