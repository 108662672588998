html{
  display: flex;
  justify-content: center;
  background: #f6f7f8;
}

body {
  /* margin: 0; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1920px;
  margin: 0 auto !important;
  width: 100%;
}

@media screen and (max-device-width: 1200px) {
  body {
    -webkit-text-size-adjust: 100%;
  }
}

div,
p,
span,
a,
u {
  font-family: "Helvetica";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* / width / */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* / Track / */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* / Handle / */
::-webkit-scrollbar-thumb {
  background: #c1c7ee;
  border-radius: 10px;
}

/* / Handle on hover / */
::-webkit-scrollbar-thumb:hover {
  background: #5e6bbd;
  cursor: pointer;
}

@font-face {
  font-family: "Phosphate";
  src: url("./fonts/Phosphate/PhosphateRR-Solid.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("./fonts/Helvetica-Neue/HelveticaNeue-Medium.otf") format("truetype");
}

table,
tr,
td,
th {
  font-size: 14px;
  font-family: "Helvetica Neue" !important;
}


@media only screen and (min-width: 1400px) and (max-width: 1535px) {
  html {
    font-size: 14px;
    /* 1rem = 20px */
  }
}

@media only screen and (min-width: 1536) {
  html {
    font-size: 16px;
    /* 1rem = 20px */
  }
}

p.Legal2L1 {
  font-weight: 600;
}

#fontFamilyTable {
  font-family: 'Helvetica' !important;
}

#boxShadowTable {
  box-shadow: none !important;
}

.MuiMenu-list {
  padding-bottom: 0px !important;
}