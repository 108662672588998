.signin-container {
  padding: 50px;
  border: 1px solid #DEDEDE;
  margin: auto;
  min-width: 400px;
}

.top-spacing {
  padding: 15% 0%;
}

.forgot-password {
  margin-left: auto;
}

.login-signin-container {
  display: flex;
  flex: 1;
  background: #fff;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  min-height: 100vh;
}

.login-signin-container-card {
  display: flex;
  flex: 1;
  background: #fff;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-height: 80%;
  min-height: 80vh;
}

.sub-text-color {
  color: #999999;
}

.combine {
  display: flex;
  flex-direction: row;
}

hr.dashed {
  border-top: 1px dashed;
  margin: 0 5rem;
}

.left-container {
  padding: 2rem 4rem;
  color: #fff;
}

.left-border {
  border-left: 1px solid #fff;
  padding-left: 1rem;
}

.pwd-container-img {
  cursor: pointer;
  width: 20px;
  right: 8px;
  top: 8px;
}

.new-row {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  /* Override autofill background color */
}