.signup-container {
  padding: 10px 30px 0px 30px;
  border: 1px solid #DEDEDE;
  margin: auto;
  min-width: 400px;
}

.top-spacing {
  padding: 15% 0%;
}

.forgot-password {
  /* margin-top: 5px; */
  /* text-align: right; */
  /* padding-left: 32px; */
  margin-left: auto;
}

.login-signup-container {
  display: flex;
  flex: 1;
  background: #fff;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  min-height: 100vh;
}

.sub-text-color {
  color: #999999;
}

.combine {
  display: flex;
  flex-direction: row;
}

hr.dashed {
  border-top: 1px dashed;
  margin: 0 5rem;
}

.left-container {
  padding: 2rem 4rem;
  color: #fff;
  /* margin-top: 10%; */
}

.left-border {
  border-left: 1px solid #fff;
  padding-left: 1rem;
}

.temp-logo {
  border: 2px solid #fff;
  border-radius: 50px;
  width: 100px;
  height: 100px;
  margin: auto;
}

.form-control {
  width: 100% !important;
  height: 44px !important;
  font: inherit !important;
}

.search-box {
  width: 93% !important;
  height: 44px !important;
}

.react-tel-input .country-list .country-name {
  width: 340px !important;
  font-size: 14px !important;
  display: none !important;
}

.react-tel-input .country-list .country .dial-code {
  font-size: 14px !important;
}

.react-tel-input .country-list {
  width: 340px !important;
  height: "12.5rem" px !important;
}

.react-tel-input :hover {
  border-color: rgba(0, 0, 0, 0.87) !important;
}

.react-tel-input .form-control {
  /* padding: 9px 14px !important; */
}

.react-tel-input .form-control:focus {
  box-shadow: none !important;
  /* border: 2px solid #3b6bb3 !important; */
  border: 2px solid #1976d2 !important;
}

.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  /* border: none !important; */
  box-shadow: none !important;
  border: 2px solid #1976d2 !important;
  height: 2.70rem !important;
  /* display: none !important; */
}

.new-row {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}