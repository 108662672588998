.btn.custom-button{
  color: #3646AC;
  border: 1px solid #3646AC;
  background-color: #fff;
}

.btn.custom-button:hover{
  color: #fff;
  border: 1px solid #fff;
  background-color: #3646AC;
}

.btn.custom-button:active{
  color: #fff;
  border: 1px solid #fff;
  background-color: #3646AC;
}

.smallDiv {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: space-between;
}